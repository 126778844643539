// consumer_feishuLogin
const __request = require(`./__request/__request_contentType_json`);
const consumer_feishuLogin = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/feishuLogin',
        check_token: false,
        data: pParameter,
        enable_error_alert: false
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_feishuLogin;
