import set_token from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/set_token";
import get_user_info from "@/lib/common-service/get_user_info";
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
import consumer_feishuLogin from '@/lib/data-service/haolv-default/consumer_feishuLogin';
import cache_get from 'nllib/src/cache/get'
import cache_set from 'nllib/src/cache/set'
export default {
    data() {
        return {
            loading: false,
            appID: '',
        }
    },
    props: {},
    components: {},
    created() {
        this.loading = true;
    },
    mounted() {
        this.appID = this.$route.query.appID || '';
        this._login();
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _login() {
            window.tt.requestAccess({
                scopeList: [],
                appID: this.appID, // 网页应用必传
                success: async (res) => {
                    let {code} = res;
                    const params = {appId: this.appID, feishuCode: code};
                    // 调用code换token接口
                    let [lErr, lRes] = await awaitWrap(consumer_feishuLogin(params));
                    if (lErr) {
                        this.loading = false;
                        this.$message(lErr.data.msg);
                        return
                    }
                    set_token({token: lRes.datas.access_token});
                    cache_set({key: 'token', value: lRes.datas.access_token});
                    cache_set({key: 'appID', value: this.appID});
                    // 关闭loading
                    this.loading = false;
                    this.$message.success('登录成功');
                    // 保存token并跳转到首页
                    get_user_info({
                        clear_cache: true,
                    })
                    const phone = lRes.datas.user_detail.phone;
                    // cache.set_user_name(phone);
                    cache_set({key: 'phone', value: phone});

                    // 鉴权
                    // await this._getAuth();
                    this.$router.replace({
                        name: `redirect`,
                    });
                },
                fail(res) {
                    this.loading = false;
                    // app_loading.hide();
                },
            });
        }
    }
}
